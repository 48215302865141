import Vue from "vue";
import Vuex from "vuex";
import authentication from './authentication'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication
  },
  state: {
    message: "Here there be dragons",
    appLoading: true
  },
  actions: {},
  getters: {},
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
            Object.assign(state, JSON.parse(localStorage.getItem('store')))
        )
      }
    }
  },
  appFinishedLoading (state) {
    state.appLoading = false
  },
});
