import Vue from "vue";
import VueRouter from 'vue-router'
import VueMeta from "vue-meta"
import routes from "./routes"
import axios from "axios"
import store from "../store"
import swal from "sweetalert2";

Vue.use(VueMeta, {
  keyName: "page"
})

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach(async (to, from, next) => {

  try {
    let isAuthenticated = false
    try {
      if (router.app.$store.state.authentication.cognitoUser !== null) {
        isAuthenticated = router.app.$store.state.authentication.cognitoUser.initiateAuthResponse.authenticationResult.refreshToken !== null
      }
    } catch(e) {
      // this.store.dispatch("logout")
      // this.router.push("Login")
      // location.reload()
      console.log('Error reading authentication state', e)
      isAuthenticated = false
    }

  console.log(`Reading requiresAuth`)
  let requiresAuth = !to.matched.some(record => record.meta.anon)

  console.log(`here ${requiresAuth} ${isAuthenticated}`)

  if (requiresAuth && !isAuthenticated) {
    console.log('redirect to login')
    next({ name: 'Login' });
  } else if (!requiresAuth && isAuthenticated) next('/');
  else next();
  } catch (error) {
    console.log('Error', error)
  }


});

Vue.use(VueRouter)

export default router
